import React from "react"

const ToplovaljaniLimoviTable = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <table
        className="table is-bordered is-hoverable"
        style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td className="is-link" colSpan={3}>
              <p style={{ textAlign: "center" }}>Standard za kvalitet</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>EN 10027</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>DIN 17100</p>
            </td>
            <td colSpan={3}>
              <p style={{ textAlign: "center" }}>JUS C.BO.500</p>
            </td>
          </tr>
          <tr>
            <td className="is-link" colSpan={3}>
              <p style={{ textAlign: "center" }}>Oznaka vrste Čelika&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 235 JRG2</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>RSt 37-2</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0361</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 235 JO</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 37-3 U</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0362</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 235 J2G3</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 37-3 N</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0363</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 275 JR</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 44-2</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0451</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 275 JO</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 44-3 U</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0452</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 275 J2G3</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 44-3 N</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0453</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 255 JR</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>&nbsp;/</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0561</p>
            </td>
          </tr>
          <tr>
            <td>
              <p style={{ textAlign: "center" }}>S 355 JO</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>St 52-3 U</p>
            </td>
            <td>
              <p style={{ textAlign: "center" }}>Č 0562</p>
            </td>
          </tr>
          <tr>
            <td
              style={{ width: "32.0285%" }}
              valign="top"
              width="32.38434163701068%"
            >
              <p style={{ textAlign: "center" }}>S 355 J2G3</p>
            </td>
            <td
              style={{ width: "31.3167%" }}
              valign="top"
              width="24.199288256227756%"
            >
              <p style={{ textAlign: "center" }}>St 52-3 N</p>
            </td>
            <td
              style={{ width: "35.5731%" }}
              valign="top"
              width="32.02846975088968%"
            >
              <p style={{ textAlign: "center" }}>Č 0563</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ToplovaljaniLimoviTable
