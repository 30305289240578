import React from "react"
import ProductsLayout from "../../../components/productsLayout"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import ToploValjaniLimoviTable from "../../../components/tables/ToplovaljaniLimoviTable"
import IPENosaciTable from "../../../components/tables/IPENosaci"

import Img1 from "../../../images/ipenosac.jpg"
import Img2 from "../../../images/ipeskica.png"
import Head from "../../../components/head"

const IPENosaci = () => {
  const title = "Toplovaljani nosači - IPE"
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
  ]
  return (
    <Layout>
      <Head title={title} />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">{title}</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi/profili-nosači">
                          Profili i Nosači
                        </Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          {title}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="columns">
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <strong>Toplovaljani nosači - IPE</strong>
                <br />I i H profili od konstukcionog čelika, tolerancije oblika
                i mera definisani standardom SRPS EN 10034 namenjeni su za
                izradu čeličnih konstukcija i drugih sklopova u industriji i
                građevinarstvu.
              </p>
              <br />

              <div className="columns is-multiline">
                <div className="column is-full">
                  <p>
                    <strong>Kvalitet čelika</strong>
                    <br />
                    Prema standardu propisa za kvalitet SRPS EN 10027-1 za opšte
                    konstrukcione čelike, uobičajeni kvalitet:
                  </p>
                </div>
                <div className="column">
                  <ul>
                    <li>DIN 17100</li>
                    <li>RSt 37-25</li>
                    <li>RSt 44-2</li>
                    <li>sT 52-3N</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>DIN 17100</li>
                    <li>RSt 37-2</li>
                  </ul>
                </div>
                <div className="column ">
                  <ul>
                    <li>SRPS EN 10027-2</li>
                    <li>1.0037</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="tab-section">
            <div class="tabs">
              <ul>
                <li className="is-active is-size-3">
                  <a>Hemijski sastav</a>
                </li>
              </ul>
            </div>
            <IPENosaciTable />
          </section>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default IPENosaci
