import React from "react"

const IPENosaci = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>Proizvodna dužina: 6 m, 12 m. Po zahtevu moguće sečenje na meru.</p>
      </div>
      <div className="table-scroll">
        <table class="table is-bordered is-hoverable">
          <tbody>
            <tr className="has-background-link has-text-white">
              <td rowSpan={2}>
                <br />
                Oznaka UPN
              </td>
              <td colSpan={5}>Mere (mm)</td>
              <td rowSpan={2}>
                <br />
                Masa (kg)
              </td>
            </tr>
            <tr>
              <td>h</td>
              <td>b</td>
              <td>s</td>
              <td>t</td>
              <td>r</td>
            </tr>
            <tr>
              <td>80</td>
              <td>80</td>
              <td>46</td>
              <td>3,8</td>
              <td>5,2</td>
              <td>5,0</td>
              <td>6,00</td>
            </tr>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>55</td>
              <td>4,1</td>
              <td>5,7</td>
              <td>7,0</td>
              <td>8,40</td>
            </tr>
            <tr>
              <td>120</td>
              <td>120</td>
              <td>64</td>
              <td>4,4</td>
              <td>6,3</td>
              <td>7,0</td>
              <td>10,40</td>
            </tr>
            <tr>
              <td>140</td>
              <td>140</td>
              <td>73</td>
              <td>4,7</td>
              <td>6,9</td>
              <td>7,0</td>
              <td>12,90</td>
            </tr>
            <tr>
              <td>160</td>
              <td>160</td>
              <td>82</td>
              <td>5,0</td>
              <td>7,4</td>
              <td>9,0</td>
              <td>15,80</td>
            </tr>
            <tr>
              <td>180</td>
              <td>180</td>
              <td>91</td>
              <td>5,3</td>
              <td>8,0</td>
              <td>9,0</td>
              <td>18,80</td>
            </tr>
            <tr>
              <td>200</td>
              <td>200</td>
              <td>100</td>
              <td>5,6</td>
              <td>8,5</td>
              <td>12,0</td>
              <td>22,40</td>
            </tr>
            <tr>
              <td>220</td>
              <td>220</td>
              <td>110</td>
              <td>5,9</td>
              <td>9,2</td>
              <td>12,0</td>
              <td>26,20</td>
            </tr>
            <tr>
              <td>240</td>
              <td>240</td>
              <td>120</td>
              <td>6,2</td>
              <td>9,8</td>
              <td>15,0</td>
              <td>30,00</td>
            </tr>
            <tr>
              <td>270</td>
              <td>270</td>
              <td>135</td>
              <td>6,6</td>
              <td>10,2</td>
              <td>15,0</td>
              <td>36,10</td>
            </tr>
            <tr>
              <td>300</td>
              <td>300</td>
              <td>150</td>
              <td>7,1</td>
              <td>10,7</td>
              <td>15,0</td>
              <td>42,20</td>
            </tr>
            <tr>
              <td>330</td>
              <td>330</td>
              <td>160</td>
              <td>7,5</td>
              <td>11,2</td>
              <td>18,0</td>
              <td>48,10</td>
            </tr>
            <tr>
              <td>360</td>
              <td>360</td>
              <td>170</td>
              <td>8,0</td>
              <td>12,7</td>
              <td>18,0</td>
              <td>57,10</td>
            </tr>
            <tr>
              <td>400</td>
              <td>400</td>
              <td>180</td>
              <td>8,6</td>
              <td>13,5</td>
              <td>21,0</td>
              <td>66,30</td>
            </tr>
            <tr>
              <td>450</td>
              <td>450</td>
              <td>190</td>
              <td>9,4</td>
              <td>14,6</td>
              <td>21,0</td>
              <td>77,60</td>
            </tr>
            <tr>
              <td>500</td>
              <td>500</td>
              <td>200</td>
              <td>10,2</td>
              <td>16,0</td>
              <td>21,0</td>
              <td>90,70</td>
            </tr>
            <tr>
              <td>550</td>
              <td>550</td>
              <td>210</td>
              <td>11,1</td>
              <td>17,2</td>
              <td>24,0</td>
              <td>106,00</td>
            </tr>
            <tr>
              <td>600</td>
              <td>600</td>
              <td>220</td>
              <td>12,0</td>
              <td>19,0</td>
              <td>24,0</td>
              <td>122,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IPENosaci
